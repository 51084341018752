<i18n>
{
  "en": {
    "browser_title": "Sitemap",
    "sitemap": "Sitemap",
    "main_pages": "Main Pages",
    "link_home": "Home",
    "link_freesample": "Free Sample",
    "link_join": "Sign Up",
    "link_faq": "FAQ",
    "video_pages": "Movies",
    "link_all_videos": "All Videos",
    "link_rankings": "Rankings",
    "link_categories": "Categories",
    "link_series": "Series",
    "link_vip": "VIP Movies",
    "link_svip": "SVIP Movies",
    "link_annual": "Annual Movies",
    "av_pages": "Pornstars",
    "link_actresses": "Actresses",
    "link_actors": "Actors",
    "member_services_pages": "Member Services",
    "link_upgrade": "Upgrade",
    "link_highspeed": "Highspeed",
    "link_ddl": "Daily Download Limit",
    "link_dml": "Daily Download Limit",
    "site_guide_pages": "Help",
    "link_contact": "Contact Us",
    "link_sitemap": "Sitemap",
    "link_agreement": "Agreement",
    "link_privacy": "Privacy",
    "access_ranking": "",
    "recommended_pages": "Recommended Pages",
    "site_carib": "Caribbeancom",
    "site_caribpr": "Caribbeancom Premium",
    "site_dxlive": "DX LIVE",
    "site_paco": "Pacopacomama",
    "site_10m": "10musume.com",
    "site_heyzo": "HEYZO"
  },
  "ja": {
    "browser_title": "サイトマップ",
    "sitemap": "サイトマップ",
    "main_pages": "メインページ",
    "link_home": "ホーム",
    "link_freesample": "無料動画",
    "link_join": "入会案内",
    "link_faq": "ヘルプ",
    "video_pages": "動画",
    "link_all_videos": "全動画一覧",
    "link_rankings": "人気ランキング",
    "link_categories": "カテゴリー",
    "link_series": "シリーズ",
    "link_vip": "VIP動画",
    "link_svip": "超VIP動画",
    "link_annual": "年間動画",
    "av_pages": "AV女優",
    "link_actresses": "AV女優一覧 (50音順)",
    "link_actors": "男優一覧 (50音順)",
    "member_services_pages": "会員サービス",
    "link_upgrade": "アップグレード案内",
    "link_highspeed": "ハイスピードプラン",
    "link_ddl": "ダウンロード増量プラン",
    "link_dml": "ダウンロード増量プラン",
    "site_guide_pages": "サイトガイド",
    "link_contact": "お問い合わせ",
    "link_sitemap": "サイトマップ",
    "link_agreement": "利用規約",
    "link_privacy": "プライバシー",
    "access_ranking": "アクセスランキング",
    "recommended_pages": "おすすめサイト",
    "site_carib": "カリビアンコム",
    "site_caribpr": "カリビアンコムプレミアム",
    "site_dxlive": "ライブチャット DXLIVE",
    "site_paco": "パコパコママ",
    "site_10m": "ムラムラってくる素人のサイト",
    "site_heyzo": "HEYZO"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />
    <div class="contents">
      <div class="heading">
        <h1>{{ $t('sitemap') }}</h1>
      </div>
      <div class="section-wide divider">
        <h2>{{ $t('main_pages') }}</h2>
        <ul class="flex-list list-sitemap">
          <li class="list-item"><router-link to="/">{{ $t('link_home') }}</router-link></li>
          <li class="list-item"><router-link to="/freesample/">{{ $t('link_freesample') }}</router-link></li>
          <li v-if="!isSiteMember" class="list-item"><router-link to="/join/">{{ $t('link_join') }}</router-link></li>
          <li class="list-item"><router-link to="/faq/">{{ $t('link_faq') }}</router-link></li>
          <li class="list-item" v-if="locale == 'en'"><a href="https://www.1pondo.tv/">日本語</a></li>
          <li class="list-item" v-if="locale == 'ja'"><a href="https://en.1pondo.tv/">English</a></li>
        </ul>
      </div>
      <div class="section-wide divider">
        <h2>{{ $t('video_pages') }}</h2>
        <ul class="flex-list list-sitemap">
          <li class="list-item"><router-link to="/list/?page=1&o=newest">{{ $t('link_all_videos') }}</router-link></li>
          <li class="list-item"><router-link to="/ranking/">{{ $t('link_rankings') }}</router-link></li>
          <li class="list-item"><router-link to="/category/">{{ $t('link_categories') }}</router-link></li>
          <li class="list-item"><router-link to="/series/">{{ $t('link_series') }}</router-link></li>
          <li v-if="!newXPEligible" class="list-item"><router-link to="/search/?page=1&size=20&mt=1">{{ $t('link_vip') }}</router-link></li>
          <li v-if="!newXPEligible" class="list-item"><router-link to="/search/?page=1&size=20&mt=2">{{ $t('link_svip') }}</router-link></li>
          <li v-if="!newXPEligible" class="list-item"><router-link to="/search/?page=1&size=20&mc=5">{{ $t('link_annual') }}</router-link></li>
        </ul>
      </div>
      <div class="section-wide divider">
        <h2>{{ $t('av_pages') }}</h2>
        <ul class="flex-list list-sitemap">
          <li class="list-item"><router-link to="/actress/">{{ $t('link_actresses') }}</router-link></li>
          <li class="list-item"><router-link to="/actor/">{{ $t('link_actors') }}</router-link></li>
        </ul>
      </div>
      <div class="section-wide divider" v-if="!newXPEligible">
        <h2>{{ $t('member_services_pages') }}</h2>
        <ul class="flex-list list-sitemap">
          <li class="list-item"><router-link to="/upgrade/">{{ $t('link_upgrade') }}</router-link></li>
          <li class="list-item"><router-link to="/highspeed/">{{ $t('link_highspeed') }}</router-link></li>
          <li v-if="!dmlEnabled" class="list-item"><router-link to="/ddl/">{{ $t('link_ddl') }}</router-link></li>
          <li v-if="dmlEnabled" class="list-item"><router-link to="/dml/">{{ $t('link_dml') }}</router-link></li>
        </ul>
      </div>
      <div class="section-wide divider">
        <h2>{{ $t('site_guide_pages') }}</h2>
        <ul class="flex-list list-sitemap">
          <li class="list-item"><router-link to="/contact/">{{ $t('link_contact') }}</router-link></li>
          <li class="list-item"><router-link to="/sitemap/">{{ $t('link_sitemap') }}</router-link></li>
          <li class="list-item"><router-link to="/agreement/">{{ $t('link_agreement') }}</router-link></li>
          <li class="list-item"><router-link to="/privacy/">{{ $t('link_privacy') }}</router-link></li>
          <li class="list-item" v-if="locale == 'ja'"><a href="http://www.adultmango.com/cgi-bin/ranklink.cgi?id=1pondo" target="_blank" rel="noopener">{{ $t('access_ranking') }}</a></li>
        </ul>
      </div>
      <div class="section-wide">
        <h2>{{ $t('recommended_pages') }}</h2>
        <ul class="flex-list list-sitemap">
          <li class="list-item"><a href="https://bit.ly/3YNngGL" target="_blank" rel="noopener">{{ $t('site_carib') }}</a></li>
          <li class="list-item"><a href="https://bit.ly/3YuE7h7" target="_blank" rel="noopener">{{ $t('site_caribpr') }}</a></li>
          <li class="list-item"><a href="https://bit.ly/3Sy9V0U" target="_blank" rel="noopener">{{ $t('site_dxlive') }}</a></li>
          <li class="list-item"><a href="https://bit.ly/4dcOn27" target="_blank" rel="noopener">{{ $t('site_heyzo') }}</a></li>
          <li class="list-item"><a href="https://bit.ly/4dLkRk7" target="_blank" rel="noopener">{{ $t('site_10m') }}</a></li>
          <li class="list-item"><a href="https://bit.ly/4d7grDU" target="_blank" rel="noopener">{{ $t('site_paco') }}</a></li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script>
/* eslint max-len: 0 */

import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';

export default {
  components: {
    'vue-headful': vueHeadful,
  },
  data() {
    return {
      dynamicTitle: '',
      dmlEnabled: APP_CONFIG.dml.enabled,
    };
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    isSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/pages/_category.scss';
</style>
